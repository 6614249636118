import { Component, OnInit } from '@angular/core';
import { NavService } from '../shared/services/nav.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {
public clearsearch

menuItems = [];
  constructor( private navService: NavService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private titleService: Title) {

 
   this.navService.items.subscribe(items => (this.menuItems = items));

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.getRouteTitle(this.activatedRoute))
      )
      .subscribe(title => this.titleService.setTitle(title));
  }

   getRouteTitle(route: ActivatedRoute): string {
    while (route.firstChild) route = route.firstChild;
    return route.snapshot.data['title'] || 'FB Cakes | Offer Now (Buy 1 kg Get 1/2 kg Free!)';
  

  

   }

  ngOnInit(): void {
  }

}
