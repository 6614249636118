import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class MyService {

  public messageSource: BehaviorSubject<string> = new BehaviorSubject<string>('jkljkljlkj');

  public _Sortingitem: BehaviorSubject<string> = new BehaviorSubject<string>('ascending');

  public cartsource: BehaviorSubject<Number> = new BehaviorSubject<Number>(0);

  public _catID: BehaviorSubject<Number> = new BehaviorSubject<Number>(0);

  public _pageId: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public PageNo: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  public HearderId: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public ChatId: BehaviorSubject<number> = new BehaviorSubject<number>(0);


  public Rewardscount: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public useredit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public SmallAdvImg: BehaviorSubject<string> = new BehaviorSubject<string>("");

  public name: BehaviorSubject<string> = new BehaviorSubject<string>('');








  // public filtersourcemin: BehaviorSubject<Number> = new BehaviorSubject<Number>(0);

  // public filtersourcemax: BehaviorSubject<Number> = new BehaviorSubject<Number>(0);





  currentMessage = this.messageSource.asObservable();


  constructor() {
  }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }
}