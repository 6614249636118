<div class="main-navbar" id="menunavbar">
  <div id="mainnav">
    <div class="toggle-nav" (click)="mainMenuToggle()">
      <i class="fa fa-bars sidebar-bar"></i>
    </div>
    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">

      <li class="back-btn">
        <div class="mobile-back text-end" (click)="mainMenuToggle()">
          <!-- <span>Back</span> -->
          <i class="fa fa-times ps-2" aria-hidden="true"></i>
        </div>
      </li>
      <!-- <li>
        <form class="form-inline search-form " style="padding-top: 32px;padding-bottom: 42px;padding-right: 45px;">
          <div>
            <span class="iInput">
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
            <ng-template #rt let-r="result" let-t="term">
              <img [src]=" r['imgUrl']" class="me-1" style="width: 16px" />
              <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
            </ng-template>

            <div class="mb-3 row">
              <div class="col">

                <input id="typeahead-template" type="text" class="form-control col input-number" [(ngModel)]="model"
                  [ngbTypeahead]="search"  [resultTemplate]="rt" [inputFormatter]="formatter"
                  (selectItem)="selectedItem($event.item)" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>


          </div>
        </form>
      </li> -->
      <!-- <a href="">My Account</a> -->

      <li *ngFor="let menuItem of menuItems" [class.mega-menu]="menuItem.megaMenu">
        <!-- Sub -->

        <a [routerLink]="menuItem.path" href="javascript:void(0)" class="nav-link"
          *ngIf="menuItem.type === 'sub' && menuItem.val === null" (click)="toggletNavActive(menuItem)">
          {{ menuItem.title | translate }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>

        <a [routerLink]="menuItem.path" href="javascript:void(0)" class="nav-link" *ngIf=" menuItem.val === denVal"
          (click)="toggletNavActive(menuItem)">
          {{ menuItem.title | translate }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>
        <!-- Link -->
        <a [routerLink]="menuItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
          class="nav-link" *ngIf="menuItem.type === 'link'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>
        <!-- External Link -->
        <a href="{{ menuItem.path }}" class="nav-link" *ngIf="menuItem.type === 'extLink'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>
        <!-- External Tab Link -->
        <a href="{{ menuItem.path }}" class="nav-link" *ngIf="menuItem.type === 'extTabLink'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>

        <!-- 2nd Level Menu -->
        <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children">
          <li *ngFor="let childrenItem of menuItem.children">
            <!-- Sub -->
            <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" (click)="toggletNavActive(childrenItem)">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <!-- Link -->
            <a [routerLink]="childrenItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              *ngIf="childrenItem.type === 'link' ">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <!-- External Link -->
            <a href="{{ childrenItem.path }}" *ngIf="childrenItem.type === 'extLink' ">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <!-- External Tab Link -->
            <a href="{{ childrenItem.path }}" target="_blank" *ngIf="childrenItem.type === 'extTabLink' ">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>

            <!-- 3rd Level Menu -->
            <ul *ngIf="childrenItem.children" [class.opensubchild]="childrenItem.active" class="nav-sub-childmenu">
              <li *ngFor="let childrenSubItem of childrenItem.children">
                <!-- Link -->
                <a [routerLink]="childrenSubItem.path" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}" *ngIf="childrenSubItem.type === 'link' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
                <!-- External Link -->
                <a href="{{ childrenSubItem.path }}" *ngIf="childrenSubItem.type === 'extLink' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
                <!-- External Tab Link -->
                <a href="{{ childrenSubItem.path }}" target="_blank" *ngIf="childrenSubItem.type === 'extTabLink' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>

        <div class="mega-menu-container" *ngIf="menuItem.megaMenu && menuItem.children" id="menunavbar2">
          <div class="container">
            <div class="row">
              <div class="col mega-box" *ngFor="let childrenItem of menuItem.children">
                <div class="link-section">
                  <div class="menu-title">
                    <h5>
                      {{ childrenItem.title | translate }}
                      <span class="sub-arrow" *ngIf="childrenItem.children"></span>
                    </h5>
                  </div>
                  <div class="menu-content opensubmegamenu">
                    <ul *ngIf="childrenItem.children">
                      <li *ngFor="let childrenSubItem of childrenItem.children">
                        <!-- Sub -->
                        <a href="javascript:void(0)" *ngIf="childrenSubItem.type === 'sub'">
                          {{ childrenSubItem.title | translate }}
                          <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate
                            }}</span>
                        </a>
                        <!-- Link -->
                        <a [routerLink]="childrenSubItem.path" routerLinkActive="active"
                          [routerLinkActiveOptions]="{exact: true}" *ngIf="childrenSubItem.type === 'link' ">
                          {{ childrenSubItem.title | translate }}
                          <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate
                            }}</span>
                        </a>
                        <!-- External Link -->
                        <a href="{{ childrenSubItem.path }}" *ngIf="childrenSubItem.type === 'extLink' ">
                          {{ childrenSubItem.title | translate }}
                          <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate
                            }}</span>
                        </a>
                        <!-- External Tab Link -->
                        <a href="{{ childrenSubItem.path }}" target="_blank"
                          *ngIf="childrenSubItem.type === 'extTabLink' ">
                          {{ childrenSubItem.title | translate }}
                          <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate
                            }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li>


        <a href="javascript:void(0)" class="nav-link" (click)="rewards()">
          Rewards
          <div class="lable-nav" *ngIf="couponlength > 0">New</div>

        </a>
      </li>

    </ul>
  </div>
</div>