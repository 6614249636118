<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <!-- <span class="lable3" *ngIf="product.new">new</span>
      <span class="lable4" *ngIf="product.sale">on sale</span> -->
    </div>
    <div id="frontimagedisplay" class="front">
      <a [routerLink]="['/shop/product/left/sidebar/', ecomproduct.pid, prodname]">
        <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
          [lazyLoad]="ImageSrc ? ImageSrc : ecomproduct?.img" class="img-fluid lazy-loading"
          alt="{{ ecomproduct?.img }}" />

      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage" id="imagehover">
      <a [routerLink]="['/shop/product/left/sidebar/', ecomproduct.pid, prodname]">
        <img [src]="ImageSrc ? ImageSrc : ecomproduct.img" class="img-fluid lazy-loading" alt="{{ ecomproduct?.img }}">
      </a>
    </div>
    <!-- <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
          <img [lazyLoad]="image.src">
        </a>
      </li>
    </ul> -->
    <div class="cart-info cart-wrap">
      <!-- <a href="javascript:void(0)" title="Add to cart" >
        <i class="fa fa-share-alt" aria-hidden="true"></i>
      </a> -->
      <!-- <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(ecomproduct)" *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
      </a> -->
      <!-- <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(ecomproduct)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a> -->
      <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(ecomproduct)">
        <i class="fa fa-heart" aria-hidden="true" [ngStyle]="ecomproduct.fav ?{'color': 'red'} : {'color': 'white'}"></i>
      </a>
      <!-- <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Compare" (click)="addToCompare(ecomproduct)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a> -->
    </div>
  </div>
  <div class="product-detail" id="app-product-box-one">
    <div>
      <bar-rating *ngIf="ecomproduct.avg_star > 0" [rate]="ecomproduct.avg_star" [readOnly]="true"></bar-rating>
      <a [routerLink]="['/shop/product/left/sidebar/', ecomproduct.pid, prodname]">
        <h6 style="margin-top: 10px;
    font-size: 16px;    font-weight: bolder;">{{ ecomproduct?.pname | titlecase }} 
    <!-- <span style="padding-bottom: 10px;font-size: 12px;font-weight: 400;"
            *ngFor="let data of ecomproduct.non_qtyprice">
            <strong class="kgavail" *ngIf="ecomproduct?.cid > 0">({{data.qty}} )</strong>
          </span> -->
        </h6>
      </a>
      <p>{{ ecomproduct?.pname }}</p>

      <h4 style="padding-bottom: 10px;font-size: medium;font-weight: 400;" *ngFor="let data of ecomproduct.preDef">
        <strong class="kg" *ngIf="ecomproduct?.cid > 0">{{data.qty}} -</strong> ₹ {{ data?.price}}
      </h4>
      <!-- <h4>
        {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
        <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
      </h4> -->
      <!-- <ul class="color-variant" *ngIf="Color(product?.variants).length">
        <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
          (click)="ChangeVariants(color, product)">
        </li>
      </ul> -->
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader" [gridscount]="grids"></app-skeleton-product-box>
<app-quick-view #quickView [ecomproduct]="ecomproduct" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [ecomproduct]="ecomproduct" [currency]="currency" *ngIf="cartModal"></app-cart-modal>