import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MyService } from '../../services/myservice';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../services/product.service';
import { ExternalService } from '../../services/external.service';

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrl: './editprofile.component.scss'
})
export class EditprofileComponent implements OnInit {

  @ViewChild('UserEdit', { static: true }) UserEdit: ElementRef<any>
  @ViewChild('referral', { static: true }) referral: ElementRef<any>
  userName: any;

  constructor(
    private myService: MyService, // Dependency injection of MyService
    private modalService: NgbModal,
    public productService: ProductService, private toastrService: ToastrService,
    public externalservice: ExternalService

  ) {
    const Name = localStorage.getItem('name');
    // console.log(Name)
    this.userName = Name

  }
  userview: any
  ngOnInit(): void {
    this.refcoupon()
    this.myService.useredit.subscribe(data => {
      // console.log(data)
      if (data == true) {
        this.userview = data
        this.modalService.open(this.UserEdit, { centered: true, size: 'l' });
      }
    })

    this.myService.referral.subscribe(data => {
      // console.log(data)
      if (data == true) {
        this.userview = data
        this.modalService.open(this.referral, { centered: true, size: 'l' });

      }
    })

  }
  savename() {
    localStorage.setItem('name', this.userName);
    this.productService.nameget.subscribe(x => {
      // console.log(x)
      this.myService.name.next(this.userName);
      if (x["status"] == 200) {
        this.toastrService.success(' Name updated Successfully!');
        this.modalService.dismissAll();


      } else {
        this.toastrService.error('LogOut', ' LogOut Successfully!');

      }



      //  localStorage.setItem('name', response["name"]);

    })


  }

  ref: any = [];
  ordercount:any
  refcoupon() {
    const cusid = localStorage.getItem('customerid');
    this.externalservice.GetRefCoupon(cusid).subscribe((resp) => {
      // console.log(resp)
      this.ordercount=resp
      this.ref = resp["refData"][0] || []
      // console.log(this.ref)
    })
  }
  genratereferral() {
    const cusid = localStorage.getItem('customerid');
    this.externalservice.GenerateRefCode(cusid).subscribe((resp) => {
      // console.log(resp)
      if (resp.status == 200) {
        this.refcoupon()
      }
    })
  }
  copyToClipboard(code: string) {
    navigator.clipboard.writeText(code).then(() => {
      this.toastrService.success(' Code Copied Successfully!');
      // this.modalService.dismissAll()
    }).catch(err => {
      // console.error("Failed to copy: ", err);
    });
  }

}
