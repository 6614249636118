import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NavService } from './shared/services/nav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  menuItems = [];
  private firstTabKey = 'firstTab';
  private tabSessionKey = 'tabSession';
  private allowedRoutes = ['/shop/checkout', '/shop/cart', '/shop/order/management'];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    private translate: TranslateService,
    private navService: NavService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.initializeSession();
    this.setupTranslations();
    this.subscribeToNavigationEvents();
  }


  ngOnInit() {
    this.setupTabSession();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.checkAndRedirect(event.url);
      }
    });

    window.addEventListener('beforeunload', this.handleTabClose.bind(this));

    // Listen for changes in localStorage to detect new tabs
    window.addEventListener('storage', (event) => {
      if (event.key === 'newTabOpened' && localStorage.getItem('newTabOpened') === 'true') {
        sessionStorage.setItem(this.tabSessionKey, 'false');
        localStorage.removeItem('newTabOpened');
        this.router.navigate(['/home/fashion']);
      }
    });

    // Listen for tab visibility change to trigger page refresh on tab switch
    if (isPlatformBrowser(this.platformId)) {
      document.addEventListener('visibilitychange', this.handleTabActivation.bind(this));
    }
  }

  private initializeSession() {
    if (!sessionStorage.getItem('firstVisit')) {
      sessionStorage.setItem('firstVisit', 'true');
    }
  }

  private setupTranslations() {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.setDefaultLang('en');
      this.translate.addLangs(['en', 'fr']);
    }
  }

  private subscribeToNavigationEvents() {
    this.navService.items.subscribe(items => (this.menuItems = items));
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.getRouteTitle(this.activatedRoute))
      )
      .subscribe(title => this.titleService.setTitle(title));
  }

  private getRouteTitle(route: ActivatedRoute): string {
    while (route.firstChild) route = route.firstChild;
    return route.snapshot.data['title'] || 'FB Cakes | Offer Now (Buy 1 kg Get 1/2 kg Free!)';
  }

  private setupTabSession() {
    const currentTabId = Date.now().toString(); // Unique ID for the tab
    sessionStorage.setItem(this.tabSessionKey, currentTabId);

    const existingFirstTab = localStorage.getItem(this.firstTabKey);

    if (!existingFirstTab) {
      // First tab scenario
      localStorage.setItem(this.firstTabKey, currentTabId);
    } else {
      // New tab scenario, update latest active tab
      localStorage.setItem(this.firstTabKey, currentTabId);
    }

    // Listen for storage events (other tabs opening)
    window.addEventListener('storage', this.handleStorageChange.bind(this));

    // console.log('Session Storage Key:', sessionStorage.getItem(this.tabSessionKey));
  }

  private checkAndRedirect(url: string) {
    const currentTabId = sessionStorage.getItem(this.tabSessionKey);
    const latestTabId = localStorage.getItem(this.firstTabKey);

    if (currentTabId !== latestTabId && this.allowedRoutes.includes(url)) {
      this.router.navigate(['/home/fashion']);
    }
  }

  private handleTabClose() {
    const currentTabId = sessionStorage.getItem(this.tabSessionKey);
    if (currentTabId === localStorage.getItem(this.firstTabKey)) {
      localStorage.removeItem(this.firstTabKey);
    }
  }

  private handleStorageChange(event: StorageEvent) {
    if (event.key === this.firstTabKey) {
      const latestTabId = localStorage.getItem(this.firstTabKey);
      const currentTabId = sessionStorage.getItem(this.tabSessionKey);

      if (currentTabId !== latestTabId) {
        this.router.navigate(['/home/fashion']);
      }
    }
  }

  private handleTabActivation() {
    // Trigger a refresh if the tab becomes active
    if (document.visibilityState === 'visible') {
      window.location.reload();
    }
  }
}
