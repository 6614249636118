import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MyService } from '../../services/myservice';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrl: './editprofile.component.scss'
})
export class EditprofileComponent implements OnInit{

  @ViewChild('UserEdit', { static: true }) UserEdit: ElementRef<any>
  userName: any;

  constructor(
    private myService: MyService, // Dependency injection of MyService
    private modalService: NgbModal,
    public productService: ProductService, private toastrService: ToastrService

  ) {
    const Name = localStorage.getItem('name');
    // console.log(Name)
    this.userName = Name

  }
  userview: any
  ngOnInit(): void {




    this.myService.useredit.subscribe(data => {
      // console.log(data)
      if (data == true) {
        this.userview = data
        this.modalService.open(this.UserEdit, { centered: true, size: 'l' });

      }



    })



  }
  savename() {
    localStorage.setItem('name', this.userName);
    this.productService.nameget.subscribe(x => {
      // console.log(x)
      this.myService.name.next(this.userName);
      if (x["status"] == 200) {
        this.toastrService.success(' Name updated Successfully!');
        this.modalService.dismissAll();


      } else {
        this.toastrService.error('LogOut', ' LogOut Successfully!');

      }



      //  localStorage.setItem('name', response["name"]);

    })


  }


}
