<div class="icon-nav" id="navbtn">
  <ul>


    <li class="onhover-div mobile-setting" style="right: 86%;">
      <div>
        <a (click)="Home()">
          <img src="" class="img-fluid" alt="">
          <i class="fa fa-home" aria-hidden="true"></i>
        </a>
      </div>
    </li>
    <li class="onhover-div mobile-setting" style="right: 68%;">
      <div>
        <a (click)="Wishlist()">
          <img src="" class="img-fluid" alt="">
          <i style="        font-size: 22px;" class="fa fa-heart" aria-hidden="true"></i>
        </a>
      </div>
    </li>

    <!-- <li class="onhover-div mobile-setting" style="right: 67%;">
      <div>
        <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i></a>
      </div> 
      
    </li> -->


    <li class="onhover-div mobile-cart" style="right: 48%;">
      <div>
        <a (click)="getcart()">
          <img src="assets/images/icon/cart.png" class="img-fluid" alt="">
          <i class="ti-shopping-cart"></i>
        </a>
      </div>
      <!-- <h1>q wre{{LivCnt}}</h1> -->
      <span class="cart_qty_cls" *ngIf="c_count">{{ c_count }}</span>
      <ul class="show-div shopping-cart" *ngIf='c_count == 0'>
        <h5>Your cart is currently empty.</h5>
      </ul>

    </li>

    <!-- <li class="onhover-div mobile-search search-widgets" style="right: 40%;">
      <div>
        <a (click)="getOrder()">
          <img src="" class="img-fluid" alt="">
          <i class="fa fa-list" aria-hidden="true"></i>
        </a>
      </div>

    </li> -->

    <li class="onhover-div mobile-search search-widgets" style="right: 27%;">
      <div>
        <a (click)="Chat()">
          <img src="" class="img-fluid" alt="">

          <i class="fa fa-headphones" aria-hidden="true"><span id='news-head'></span> </i>
        </a>
      </div>

    </li>


  </ul>
</div>



<ng-template #loginModel let-modal>
  <div class="modal-body" id="loginmodel">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="modal-body">
          <strong style="font-weight: 700;">
            <h5>Your Not Logged In, Please Login to proceed to Cart!..</h5>
          </strong>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-solid" data-dismiss="modal"
            (click)="modal.dismiss('Cross click')">Close</button>
          <button type="button" class="btn btn-solid" (click)="logincheck()">Login</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>