<ng-template #UserEdit let-modal>
    <div class="modal-body" style="padding: 30px;">
        <div class="container-fluid p-0">
            <div class="row justify-content-center">
                <!-- Modal Content -->
                <div class="col-md-8 col-sm-10 col-xs-12">

                    <!-- Modal Header -->
                    <div class="text-center" style="margin-bottom: 20px;">
                        <div class="icon-box" style="margin-bottom: 15px;">
                            <label class="modal-title"
                                style="font-weight: 600; font-size: 18px; margin-top: 20px; display: block;">
                                Edit Profile Name
                            </label>
                            <i class="fa fa-user-edit" aria-hidden="true"
                                style="font-size: 30px; margin-top: 10px;"></i>
                        </div>
                        <div>
                            <i class="fa fa-user-circle fa-5x" aria-hidden="true" style="margin-bottom: 15px;"></i>
                        </div>
                    </div>

                    <!-- Name Input -->
                    <!-- <div class="form-group" style="margin-bottom: 20px;">
                        <input id="typeahead-template" type="text" class="form-control" [(ngModel)]="userName" placeholder="Enter your new name" maxlength="10"
                               style="text-align: center; font-size: 16px; border-radius: 1px; padding: 10px; margin-top: 10px;">
                    </div> -->

                    <div class="form-group" style="margin-bottom: 20px;">
                        <input id="typeahead-template" type="text" class="form-control" [(ngModel)]="userName"
                            placeholder="Enter your new name" maxlength="18"
                            style="text-align: center; font-size: 16px; border-radius: 5px; padding: 10px; margin-top: 10px;
                               border: 1px solid #dcdcdc; box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); transition: all 0.3s ease;">
                    </div>


                    <!-- Modal Footer -->
                    <div class="modal-footer justify-content-center"
                        style="padding: 10px 20px; border-top: 1px solid #ddd; ">
                        <button type="button" class="btn btn-secondary btn-sm btn-solid"
                            (click)="modal.dismiss('Cross click')"
                            style="border-radius: 20px; padding: 8px 20px; margin-right: 10px;">
                            Cancel
                        </button>

                        <button type="button" class="btn btn-primary btn-sm btn-solid" (click)="savename()"
                            style="border-radius: 20px; padding: 8px 20px; margin-left: 20px;">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>





<ng-template #referral let-modal>
    <div class="modal-body p-4">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12 text-center"> 
                    <h5 class="fw-bold" style="font-size: x-large;">Referral</h5>
                </div>
                <div class="col-12 text-end">
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()"
                        style="position: absolute; top: 10px; right: 15px; font-size: 24px; border: none; background: transparent;">
                        ×
                    </button>
                </div>
            </div>
            <br>
            <div class="col-12">
                <div class="text-center mb-3" *ngIf="ordercount.orderCount == 0">
                    <label class="modal-title fs-5">
                        Unlock Referrals by placing orders
                    </label>  
                </div>
                <div class="text-center mb-3" *ngIf="ordercount.orderCount > 0" >
                    <label class="modal-title fs-5">
                        Refer a friend and unlock exclusive benefits for both of you!
                    </label>
                    <p>{{ ref.desc }}</p>
                    <div class="row justify-content-center">
                        <div  *ngIf="ref.refCode"class="d-flex flex-row justify-content-center align-items-center border rounded p-2 shadow-sm bg-light mt-2"
    style="max-width: 220px; text-align: center; gap: 15px;">
    <span class="fw-bold text-danger" style="font-size: 1.3rem; letter-spacing: 2px;">{{ ref.refCode }}</span>
    <span *ngIf="ref.refCode" class="text-grey" style="cursor: pointer;"
    (click)="copyToClipboard(ref.refCode)" title="Copy ">
    <i class="fa fa-clipboard" aria-hidden="true"></i>
</span>
</div>
<div *ngIf="!ref.refCode && ordercount.orderCount > 0" class="gradient-box"></div>

                    </div>
                </div>
                <div   *ngIf="!ref.refCode && ordercount.orderCount > 0" class="modal-footer justify-content-center border-top pt-2">
                    <button type="button" class="btn btn-primary btn-sm"
                        (click)="genratereferral()" style="border-radius: 20px; padding: 8px 20px;">
                        Generate Code
                    </button>
                </div>
            </div>
        </div>
    </div>
    
</ng-template>