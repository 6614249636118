<div class="collection-collapse-block border-0" [class.open]="collapse" id="category">
    <h3 class="collapse-block-title" (click)="collapse = !collapse">Category</h3>
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <ul class="category-list">
                <li *ngFor="let category of Category">
                    <a (click)="selectid(category.id )" [style.color]="(category.id ==  someCondition) ? 'red' : '#777777'" 
                        [routerLink]="['/shop/collection/left/sidebar']"
                        [queryParams]="{ category: category.id + '-' + category.description }">
                        {{ category.description }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>