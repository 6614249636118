import { Component, OnInit, Input } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { OperatorFunction, Observable, distinctUntilChanged, map } from 'rxjs';
import { debounceTime } from 'rxjs';
import { ProductService } from '../../services/product.service';
import { MyService } from '../../services/myservice';
import { ExternalService } from '../../services/external.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input('icon') public icon;
  public menuItems: Menu[];
  public isOpenMobile: boolean;

  public AllSearchProd
  constructor(private router: Router, public navServices: NavService, public productService: ProductService, private myService: MyService, public externalservice: ExternalService,) {
    this.navServices.items.subscribe(menuItems => this.menuItems = menuItems);
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });

    // this.productService.getAllProducts.subscribe(Cat => {
    //   const data = Cat["products"]
    //   this.AllSearchProd = data
    //   console.log(this.AllSearchProd)
    // });

    // const data = localStorage.getItem('myApp_Search');
    // console.log(data);
    // let data1 = data || null;

    // if (data1 !== null) {
    //   data1 = data.replace(/"/g, '');
    // }

    // this.model = data1 || '';
    // console.log(this.model)
    if (localStorage.getItem('denglu') === '1') {
      // this.denVal = localStorage.getItem('denglu') || 0;
      const data = localStorage.getItem('denglu');
      this.denVal = parseInt(data, 10) || 0;
      // console.log(this.denVal)
    }
    // this.getCoupons()
  }
  model: any;
  denVal: number = 0


  // search: OperatorFunction<string, readonly { name; imgUrl }[]> = (text$: Observable<string>) =>
  //   text$.pipe(
  //     debounceTime(200),
  //     map((term) =>
  //       term === ''
  //         ? []
  //         : this.AllSearchProd.filter((v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10),
  //     ),
  //   );

  // formatter = (x: { name: string }) => x.name || this.model;

  couponcount: any = 0
  ngOnInit(): void {
    this.getCoupons()
  }
  Coupons: any = []
  couponlength: number = 0
  getCoupons() {
    const cusid = localStorage.getItem('customerid');
    const custId = parseInt(cusid, 10) || 0;
    this.externalservice.getCoupons(custId).subscribe(coup => {

      this.couponlength = coup["couponcount"];
      // console.log(this.couponlength);
    });
  }

  selectedItem(item) {
    // console.log(item)
    // this.router.navigateByUrl('/shop/collection/left/sidebar?ProdId=' + item.id )
    this.router.navigateByUrl('/shop/product/left/sidebar/' + item.id + '/' + item.name);
    localStorage.setItem("myApp_Search", JSON.stringify(item.name));
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  rewards() {
    // this.router.navigate(['/pages/Rewards'], { queryParams: { name: 'Coupons' } });

    this.router.navigate(['/pages/Rewards'], { queryParams: { name: 'Coupons' } });

  }

}
